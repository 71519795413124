import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const castAllParams = (route) => {
  let castParams = {}
  for (const paramName in route.params) {
    const paramValue = Number.parseInt(route.params[paramName], 10)
    if (Number.isNaN(paramValue)) {
      castParams[paramName] = null
    } else {
      castParams[paramName] = Vue.observable(paramValue)
    }
  }
  return castParams
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/IntroPage')
  },
  {
    path: '/portfolios/:portfolio_id/buildings',
    name: 'portfolio',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio" */ '@/pages/PortfolioPage')
  },
  {
    path: '/portfolios/:portfolio_id/buildings/add',
    name: 'addBuilding',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "add-portfolio" */ '@/pages/AddBuildingPage')
  },
  {
    path: '/portfolios/:portfolio_id/buildings/:building_id',
    name: 'building',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "building" */ '@/pages/BuildingPage')
  },
  {
    path: '/portfolios/:portfolio_id/heat-grids',
    name: 'heatGrids',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "heat-grids" */ '@/pages/settings/HeatGridsPage')
  },
  {
    path: '/portfolios/:portfolio_id/report',
    name: 'report',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "report" */ '@/pages/ReportPage')
  },
  {
    path: '/portfolios/:portfolio_id/scenarios',
    name: 'scenarios',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "scenarios" */ '@/pages/ScenariosPage')
  },
  /* {
    path: '/portfolios/:portfolio_id/scenarios/:scenario_id',
    name: 'viewScenario',
    props: castAllParams,
    component: () => import('@/pages/EditScenarioPage')
  }, */
  {
    path: '/portfolios/:portfolio_id/settings',
    name: 'settings',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "scenarios" */ '@/pages/settings/PortfolioSettingsPage')
  },
  {
    path: '/portfolios/:portfolio_id/energy-carriers',
    name: 'settingsEnergyCarriers',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-energy-carriers" */ '@/pages/settings/EnergyCarriersPage')
  },
  {
    path: '/portfolios/:portfolio_id/investment-costs',
    name: 'settingsInvestmentCosts',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-investment-costs" */ '@/pages/settings/InvestmentCostsPage')
  },
  {
    path: '/portfolios/:portfolio_id/heating-types',
    name: 'settingsHeatingTypes',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-heating-types" */ '@/pages/settings/HeatingTypesPage')
  },
  {
    path: '/portfolios/:portfolio_id/lifespans',
    name: 'settingsLifespans',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-lifespans" */ '@/pages/settings/LifespansPage')
  },
  {
    path: '/portfolios/:portfolio_id/targets',
    name: 'settingsTargets',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-targets" */ '@/pages/settings/TargetsPage')
  },
  {
    path: '/portfolios/:portfolio_id/tag-groups',
    name: 'settingsTagGroups',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "portfolio-tag-groups" */ '@/pages/settings/TagGroupsPage')
  },
  /*
   *
   * USER ADMIN PAGES
   *
   */
  {
    path: '/admin/users',
    name: 'adminUsers',
    component: () => import(/* webpackChunkName: "admin-users" */ '@/pages/admin-users/OverviewPage')
  },
  {
    path: '/admin/users/new',
    name: 'adminAddUser',
    component: () => import(/* webpackChunkName: "admin-add-user" */ '@/pages/admin-users/AddNewPage')
  },
  {
    path: '/admin/users/:user_id',
    name: 'adminUser',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-user" */ '@/pages/admin-users/DetailsPage')
  },
  {
    path: '/admin/users/:user_id/edit',
    name: 'adminEditUser',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-edit-user" */ '@/pages/admin-users/EditPage')
  },
  {
    path: '/admin/users/:user_id/portfolios/add',
    name: 'adminAddPortfolioToUser',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-add-portfolio-to-user" */ '@/pages/admin-users/AddPortfolioToUserPage')
  },
  /*
   *
   * PORTFOLIO ADMIN PAGES
   *
   */
  {
    path: '/admin/portfolios',
    name: 'adminPortfolios',
    component: () => import(/* webpackChunkName: "admin-portfolios" */ '@/pages/admin-portfolios/OverviewPage')
  },
  {
    path: '/admin/portfolios/new',
    name: 'adminAddPortfolio',
    component: () => import(/* webpackChunkName: "admin-add-portfolio" */ '@/pages/admin-portfolios/AddNewPage')
  },
  {
    path: '/admin/portfolios/import',
    name: 'adminImportPortfolio',
    component: () => import(/* webpackChunkName: "admin-add-portfolio" */ '@/pages/admin-portfolios/ImportPage')
  },
  {
    path: '/admin/portfolios/:portfolio_id',
    name: 'adminPortfolioDetails',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-portfolio" */ '@/pages/admin-portfolios/DetailsPage')
  },
  {
    path: '/admin/portfolios/:portfolio_id/buildings',
    name: 'adminPortfolioBuildings',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-portfolio-buildings" */ '@/pages/admin-portfolios/ManageBuildingsPage')
  },
  {
    path: '/admin/portfolios/:portfolio_id/settings',
    name: 'adminPortfolioSettings',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-portfolio-settings" */ '@/pages/admin-portfolios/EditSettingsPage')
  },
  {
    path: '/admin/portfolios/:portfolio_id/addbuildings',
    name: 'adminPortfolioAddBuildings',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-portfolio-add-buildings" */ '@/pages/admin-portfolios/AddBuildingsPage')
  },
  {
    path: '/admin/portfolios/:portfolio_id/users',
    name: 'adminPortfolioUsers',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-portfolio-users" */ '@/pages/admin-portfolios/ManageUsersPage')
  },
  {
    path: '/admin/portfolios/:portfolio_id/users/add',
    name: 'adminAddUserToPortfolio',
    props: castAllParams,
    component: () => import(/* webpackChunkName: "admin-add-user-to-portfolio" */ '@/pages/admin-portfolios/AddUserToPortfolioPage')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/LoginPage')
  },
  {
    path: '*',
    redirect: {
      name: 'home'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['login']
  const authRequired = !publicPages.includes(to.name)

  const user = JSON.parse(localStorage.getItem('user'))
  let token = user && user.authentication_token

  if (authRequired && !token) {
    return next({ name: 'login', query: { redirect: to.path } })
  }
  return next()
})

export default router
