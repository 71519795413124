import router from '@/router'
import store from '@/store'

const auth = () => {
  const token = store.getters['account/token']
  const lang = store.getters['locale/lang']
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  if (token) {
    headers['Authentication-Token'] = token
  }
  if (lang) {
    headers['Accept-Language'] = lang
  }
  return headers
}

function UnprocessableEntityError (invalidFields) {
  this.invalidFields = invalidFields
  this.name = 'UnprocessableEntityError'
  this.message = 'Invalid request'
}

const logout = () => {
  localStorage.removeItem('user')
}

const login = async (email, password) => {
  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Accept-Language': store.getters['locale/lang'],
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}/accounts/login?include_auth_token&include_user`, options)
  if (!response) {
    throw new Error('Network response was not ok.')
  }
  const data = await response.json()
  if (response.ok) {
    // store user details and token in local storage to keep user logged in between visits
    localStorage.setItem('user', JSON.stringify(data.response.user))
    return data.response.user
  } else if (response.status === 401) {
    logout()
    router.replace('/')
  } else if (response.statusText) {
    throw new Error(response.statusText)
  } else {
    throw new Error('Network response was not ok.')
  }
}

const call = async (url, method, body) => {
  let options = {
    headers: auth()
  }
  if (method === undefined) {
    options.method = 'GET'
  } else {
    options.method = method
  }
  if (body !== undefined) {
    options.body = JSON.stringify(body)
  }
  const response = await fetch(`${process.env.VUE_APP_API_URL}${url}`, options)
  if (!response) {
    throw new Error('Network response was not ok.')
  }
  const data = await response.json()
  if (response.ok) {
    return data
  } else if (response.status === 401) {
    logout()
    router.replace('/')
  } else if (response.status === 422) {
    throw new UnprocessableEntityError(data.errors.json)
  } else if (response.statusText) {
    throw new Error(response.statusText)
  } else {
    throw new Error('Network response was not ok.')
  }
}

const api = {
  call,
  login,
  logout,
  UnprocessableEntityError
}

export default api
