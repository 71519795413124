import Vue from 'vue'
import WebFont from 'webfontloader'

import Chart from 'chart.js'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import i18n from '@/plugins/i18n'

import App from '@/App'
import router from '@/router'
import store from '@/store'
import VueLuxon from 'vue-luxon'

Vue.config.productionTip = false
Vue.config.devtools = true

i18n.locale = store.state.locale.current

WebFont.load({
  google: {
    families: ['Source Sans Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i']
  }
})

// Overwrite default chart styling
Chart.defaults.global.tooltips = { ...Chart.defaults.global.tooltips,
  titleSpacing: 10,
  bodySpacing: 10,
  titleFontSize: 14,
  titleMarginBottom: 10,
  xPadding: 12,
  yPadding: 12,
  cornerRadius: 2
}

Vue.component('v-select', vSelect)
Vue.use(VueLuxon)

Vue.directive('matchText', (el, binding) => {
  const text = el.textContent
  const searchText = binding.value
  if (searchText) {
    const match = text.toLowerCase().match(searchText)
    if (match) {
      el.innerHTML = `<em class="match">${text}</em>`
      return
    }
  }
  el.innerHTML = text
})

Vue.mixin({
  methods: {
    /*
    * Precision: Number of digits after the point (negative numbers round to 10/100/...)
    */
    formatNumber: (x, precision) => {
      if (x === undefined || x === null) {
        return x
      }
      if (precision !== undefined) {
        x = Math.round(x * Math.pow(10, precision)) / Math.pow(10, precision)
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    }
  }
})

Vue.directive('focus', (el) => {
  el.focus()
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
