const buildingUsageColors = {
  'RESIDENTIAL': 'hsl(187, 73%, 39%)',
  'RESIDENTIAL_SFH': 'hsl(173, 83%, 39%)',
  'OFFICE': 'hsl(300, 10%, 54%)',
  'SCHOOLS': 'hsl(342, 58%, 75%)',
  'RETAIL': 'hsl(209, 14%, 68%)',
  'RESTAURANT': 'hsl(177, 42%, 78%)',
  'EVENT_BUILDING': 'hsl(18, 52%, 85%)',
  'HOSPITAL': 'hsl(342, 48%, 55%)',
  'INDUSTRIAL': 'hsl(322, 31%, 43%)',
  'STORAGE': 'hsl(16, 75%, 87%)',
  'SPORTS': 'hsl(342, 58%, 75%)',
  'INDOOR_SWIMMING': 'hsl(342, 68%, 85%)',
  'UNKNOWN': 'hsl(54, 0%, 50%)'
}

const dataQualityColors = {
  'FULL': 'hsl(101, 55%, 42%)',
  'ESTIMATE': 'hsl(65, 100%, 40%)',
  'GIS_ESTIMATE': 'hsl(50, 100%, 40%)',
  'MISSING': 'hsl(5, 80%, 67%)'
}

const ewsFeasibilityColors = {
  'NON_ADMISSIBLE': 'hsl(358, 76%, 36%)',
  'ADMISSIBLE': 'hsl(101, 55%, 42%)',
  'LIMITATIONS': 'hsl(70, 100%, 40%)',
  'ERROR': 'hsl(5, 7%, 67%)',
  'UNKNOWN': 'hsl(0, 0%, 83%)'
}

const gwFeasibilityColors = {
  'NON_ADMISSIBLE': 'hsl(358, 76%, 36%)',
  'ADMISSIBLE': 'hsl(101, 55%, 42%)',
  'LIMITATIONS': 'hsl(70, 100%, 40%)',
  'BAD_GEOLOGY': 'hsl(359, 77%, 56%)',
  'ERROR': 'hsl(5, 7%, 67%)',
  'UNKNOWN': 'hsl(0, 0%, 83%)'
}

const districtAvailabilityColors = {
  'NONE': 'hsl(359, 77%, 56%)',
  'UNKNOWN': 'hsl(0, 0%, 83%)',
  'EXISTING': 'hsl(101, 55%, 42%)',
  'EXISTING_NEAR': 'hsl(60, 60%, 62%)',
  'PLANNED': 'hsl(81, 77%, 41%)',
  'PLANNED_NEAR': 'hsl(60, 60%, 72%)',
  'EVALUATION': 'hsl(61, 100%, 42%)',
  'EVALUATION_NEAR': 'hsl(60, 60%, 82%)',
  'ERROR': 'hsl(5, 7%, 67%)',
  'HEAT_POTENTIAL_NEAR': 'hsl(75, 80%, 62%)'
}

const usageColors = {
  'ROOM_HEAT': 'hsl(341, 74%, 61%)',
  'HOT_WATER': 'hsl(356, 69%, 80%)',
  'APPLIANCES': 'hsl(269, 57%, 10%)',
  'LIGHTNING': 'hsl(300, 30%, 20%)',
  'VENTILATION': 'hsl(321, 9%, 37%)',
  'GENERAL': 'hsl(54, 9%, 57%)'
}

const meterTypeColors = {
  'END_ENERGY_HEATER': 'hsl(341, 74%, 61%)',
  'ELECTRICITY_TENANTS': 'hsl(356, 69%, 80%)',
  'ELECTRICITY_GENERAL': 'hsl(269, 57%, 10%)',
  'ELECTRICITY_PV': 'hsl(300, 30%, 20%)'
}

const carrierColors = {
  'HEATING_OIL': 'hsl(4, 82%, 67%)',
  'NATURAL_GAS': 'hsl(29, 97%, 86%)',
  'DISTRICT_HEAT': 'hsl(185, 10%, 40%)',
  'LOCAL_HEAT': 'hsl(185, 10%, 30%)',
  'SOLAR_HEAT': 'hsl(185, 10%, 55%)',
  'PELLET': 'hsl(25, 57%, 38%)',
  'WOOD_CHIPS': 'hsl(22, 47%, 18%)',
  'ELECTRICITY_GRID': 'hsl(194, 100%, 33%)',
  'ELECTRICITY_OWNUSE': 'hsl(183, 100%, 36%)',
  'ELECTRICITY_FEEDIN': 'hsl(180, 70%, 75%)'
}

const heatingColors = {
  'OIL': 'hsl(21, 47%, 64%)',
  'GAS': 'hsl(21, 47%, 78%)',
  'DISTRICT': 'hsl(276, 0%, 61%)',
  'LOCAL': 'hsl(276, 0%, 51%)',
  'PELLET': 'hsl(347, 45%, 75%)',
  'WOOD_CHIPS': 'hsl(347, 45%, 75%)',
  'HP_EWS': 'hsl(213, 46%, 72%)',
  'HP_AIR': 'hsl(213, 46%, 88%)',
  'HP_GW': 'hsl(213, 46%, 80%)'
}

// https://coolors.co/f72585-b5179e-7209b7-560bad-480ca8-3a0ca3-3f37c9-4361ee-4895ef-4cc9f0
const measureColors = {
  'WALLS': 'hsl(243, 57%, 50%)',
  'WINDOWS': 'hsl(229, 83%, 60%)',
  'BASEMENT': 'hsl(212, 84%, 61%)',
  'ROOF': 'hsl(194, 85%, 62%)',
  'HEATING': 'hsl(333, 93%, 56%)',
  'PV': 'hsl(309, 77%, 40%)'
}

const scenarioColors = [
  'hsla(208, 45%, 45%, 1.0)',
  'hsla(21, 100%, 56%, 1.0)',
  'hsla(94, 58%, 59%, 1.0)',
  'hsla(359, 100%, 62%, 1.0)',
  'hsla(42, 100%, 64%, 1.0)',
  'hsla(162, 63%, 46%, 1.0)',
  'hsla(33, 100%, 55%, 1.0)'
]

const pvNpvColors = {
  'investment': 'hsla(39, 45%, 62%)',
  'subsidies': 'hsla(68, 45%, 62%)',
  'revenue_ownuse': 'hsla(203, 45%, 62%)',
  'revenue_feedin': 'hsla(203, 45%, 70%)',
  'operation_costs': 'hsla(19, 45%, 62%)',
  'cumulative_total': 'hsla(68, 0%, 40%)'
}

const colors = {
  ewsFeasibilityColors,
  gwFeasibilityColors,
  districtAvailabilityColors,
  buildingUsageColors,
  usageColors,
  meterTypeColors,
  carrierColors,
  heatingColors,
  measureColors,
  scenarioColors,
  pvNpvColors,
  dataQualityColors
}

export default colors
