import Vue from 'vue'
import compassApi from '@/services/compass-api'
import colorPalettes from '@/services/color-palettes'

const state = {
  portfolios: null, // List of portfolios (with or without details)
  portfolios_loading: false,
  portfolios_loaded: false,
  query: { // Current search query
    search: '',
    sort_order: 'identifier',
    sort_reverse: false,
    filters: {}
  }
}

// getters
const getters = {
  getAll (state) {
    return state.portfolios
  },
  getById (state) {
    return (portfolioId) => {
      if (state.portfolios === null) {
        return undefined
      }
      return state.portfolios.find(p => p.id === portfolioId)
    }
  },
  query (state) {
    return state.query
  }
}

// actions
const actions = {
  /*
   * Loaders
   */
  // Load list of all portfolios (compact summary)
  async loadAll ({ state, commit }) {
    if (!state.portfolios_loading && !state.portfolios_loaded) {
      commit('portfoliosLoading')
      const portfolios = await compassApi.call('/portfolios')
      commit('portfoliosLoaded', { portfolios })
    }
  },
  async refreshAll ({ commit }) {
    commit('portfoliosLoading')
    const portfolios = await compassApi.call('/portfolios')
    commit('portfoliosLoaded', { portfolios })
  },
  // Load details of one portfolio
  async loadDetailsById ({ state, commit }, portfolioId) {
    if (state.portfolios_loaded) {
      const portfolio = state.portfolios.find(p => p.id === portfolioId)
      if (portfolio === undefined || (!portfolio.__details_loading && !portfolio.__details_loaded)) {
        commit('detailsLoading', { portfolioId })
        const details = await compassApi.call(`/portfolios/${portfolioId}`)
        commit('detailsLoaded', { portfolioId, details })
      }
    }
  },
  // Refresh details of one portfolio
  async refreshDetailsById ({ commit }, portfolioId) {
    commit('detailsLoading', { portfolioId })
    const details = await compassApi.call(`/portfolios/${portfolioId}`)
    commit('detailsLoaded', { portfolioId, details })
  },
  // Load list of users for one portfolio
  async loadUsersById ({ state, commit }, portfolioId) {
    if (state.portfolios_loaded) {
      const portfolio = state.portfolios.find(p => p.id === portfolioId)
      if (portfolio === undefined || (!portfolio.__users_loading && !portfolio.__users_loaded)) {
        commit('usersLoading', { portfolioId })
        const users = await compassApi.call(`/portfolios/${portfolioId}/users`)
        commit('usersLoaded', { portfolioId, users })
      }
    }
  },
  // Refresh list of users for one portfolio
  async refreshUsersById ({ commit }, portfolioId) {
    commit('usersLoading', { portfolioId })
    const users = await compassApi.call(`/portfolios/${portfolioId}/users`)
    commit('usersLoaded', { portfolioId, users })
  },
  // Load buildings for one portfolio
  async loadBuildingsById ({ commit, state }, portfolioId) {
    if (state.portfolios_loaded) {
      const portfolio = state.portfolios.find(p => p.id === portfolioId)
      if (portfolio === undefined || (!portfolio.__buildings_loading && !portfolio.__buildings_loaded)) {
        const query = JSON.stringify(state.query)
        commit('buildingsLoading', { portfolioId })
        const buildings = await compassApi.call(`/portfolios/${portfolioId}/buildings?query=${query}`)
        commit('buildingsLoaded', { portfolioId, buildings })
      }
    }
  },
  // Refresh buildings for one portfolio
  async refreshBuildingsById ({ commit, state }, portfolioId) {
    const query = JSON.stringify(state.query)
    commit('buildingsLoading', { portfolioId })
    const buildings = await compassApi.call(`/portfolios/${portfolioId}/buildings?query=${query}`)
    commit('buildingsLoaded', { portfolioId, buildings })
  },
  /*
   * Create, edit and delete Portfolios
   */
  async deletePortfolio ({ dispatch }, id) {
    await compassApi.call(`/portfolios/${id}`, 'DELETE')
    dispatch('refreshAll')
  },
  async addPortfolio ({ dispatch }, portfolio) {
    const newPortfolio = await compassApi.call(`/portfolios`, 'POST', portfolio)
    dispatch('refreshAll')
    return newPortfolio
  },
  async updatePortfolioName ({ dispatch }, { id, name }) {
    await compassApi.call(`/portfolios/${id}/name`, 'PUT', { name: name })
    dispatch('refreshDetailsById', id)
  },
  async updateParams ({ dispatch }, { id, params }) {
    await compassApi.call(`/portfolios/${id}/params`, 'PUT', params)
    dispatch('refreshDetailsById', id)
  },
  async addPortfolioUser ({ dispatch }, { portfolioId, userId, role }) {
    await compassApi.call(`/portfolios/${portfolioId}/users`, 'POST', { id: userId, role: role })
    dispatch('refreshUseryById', portfolioId)
    dispatch('users/loadAll', {}, { root: true })
  },
  async removePortfolioUser ({ dispatch }, { portfolioId, userId }) {
    await compassApi.call(`/portfolios/${portfolioId}/users/${userId}`, 'DELETE')
    dispatch('refreshUseryById', portfolioId)
    dispatch('users/loadAll', {}, { root: true })
  },
  /*
   * Create, edit and delete Targets
   */
  async addTarget ({ dispatch }, target) {
    await compassApi.call(`/targets`, 'POST', target)
    dispatch('refreshDetailsById', target.portfolio_id)
  },
  async updateTarget ({ dispatch }, { id, target }) {
    await compassApi.call(`/targets/${id}`, 'PUT', target)
    dispatch('refreshDetailsById', target.portfolio_id)
  },
  async deleteTarget ({ dispatch }, target) {
    await compassApi.call(`/targets/${target.id}`, 'DELETE')
    dispatch('refreshDetailsById', target.portfolio_id)
  },
  /*
   * Create, edit and delete Tags and TagGroups
   */
  async addTagGroup ({ dispatch }, tagGroup) {
    await compassApi.call(`/tags/groups`, 'POST', tagGroup)
    dispatch('refreshDetailsById', tagGroup.portfolio_id)
  },
  async updateTagGroup ({ dispatch }, { id, tagGroup }) {
    await compassApi.call(`/tags/groups/${id}`, 'PUT', tagGroup)
    dispatch('refreshDetailsById', tagGroup.portfolio_id)
  },
  async deleteTagGroup ({ dispatch }, tagGroup) {
    await compassApi.call(`/tags/groups/${tagGroup.id}`, 'DELETE')
    dispatch('refreshDetailsById', tagGroup.portfolio_id)
  },
  async addTag ({ dispatch }, tag) {
    await compassApi.call(`/tags/tags`, 'POST', tag)
    // dispatch('refreshDetailsById', id)
  },
  async updateTag ({ dispatch }, { id, tag }) {
    await compassApi.call(`/tags/tags/${id}`, 'PUT', tag)
    // dispatch('refreshDetailsById', id)
  },
  async deleteTag ({ dispatch }, tag) {
    await compassApi.call(`/tags/tags/${tag.id}`, 'DELETE')
    // dispatch('refreshDetailsById', id)
  },
  /*
   * Create, edit and delete HeatGrids
   */
  async addHeatGrid ({ dispatch }, heatGrid) {
    await compassApi.call(`/heat_grids`, 'POST', heatGrid)
    dispatch('refreshDetailsById', heatGrid.portfolio_id)
  },
  async updateHeatGrid ({ dispatch }, { id, heatGrid }) {
    await compassApi.call(`/heat_grids/${id}`, 'PUT', heatGrid)
    dispatch('refreshDetailsById', heatGrid.portfolio_id)
  },
  async deleteHeatGrid ({ dispatch }, heatGrid) {
    await compassApi.call(`/heat_grids/${heatGrid.id}`, 'DELETE')
    dispatch('refreshDetailsById', heatGrid.portfolio_id)
  },
  /*
   * Create, edit and delete Scenarios
   */
  async resetScenarios ({ commit }, portfolioId) {
    await compassApi.call(`/portfolios/${portfolioId}/resetscenarios`, 'PUT')
    const portfolios = await compassApi.call('/portfolios')
    commit('portfoliosLoaded', { portfolios })
  },
  async addScenario ({ dispatch }, scenario) {
    await compassApi.call(`/scenarios`, 'POST', scenario)
    dispatch('refreshDetailsById', scenario.portfolio_id)
  },
  async updateScenario ({ dispatch }, scenario) {
    await compassApi.call(`/scenarios/${scenario.id}`, 'PUT', scenario)
    dispatch('refreshDetailsById', scenario.portfolio_id)
  },
  async deleteScenario ({ dispatch }, scenario) {
    await compassApi.call(`/scenarios/${scenario.id}`, 'DELETE')
    dispatch('refreshDetailsById', scenario.portfolio_id)
  },
  /*
   * Building search query and filters
   */
  setQuery ({ dispatch, commit }, { portfolioId, query }) {
    commit('setQuery', query)
    dispatch('refreshBuildingsById', portfolioId)
  },
  clearTagFilters ({ dispatch, commit }, portfolioId) {
    commit('clearTagFilters')
    dispatch('refreshBuildingsById', portfolioId)
  },
  setTagFilters ({ dispatch, commit }, { payload, portfolioId }) {
    commit('setTagFilters', payload)
    dispatch('refreshBuildingsById', portfolioId)
  }
}

// mutations
const mutations = {
  // When buildings are being loaded
  buildingsLoading (state, { portfolioId }) {
    let portfolio = state.portfolios.find(o => o.id === portfolioId)
    Vue.set(portfolio, '__buildings_loading', true)
    // Vue.set(portfolio, '__buildings_loaded', false)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },
  // When buildings have been loaded
  buildingsLoaded (state, { portfolioId, buildings }) {
    let portfolio = state.portfolios.find(o => o.id === portfolioId)
    Vue.set(portfolio, '__buildings_loading', false)
    Vue.set(portfolio, '__buildings_loaded', true)
    Vue.set(portfolio, 'buildings', [...buildings.buildings])
    Vue.set(portfolio, 'tag_counts', buildings.tag_counts)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },
  // When users are being loaded
  usersLoading (state, { portfolioId }) {
    let portfolio = state.portfolios.find(o => o.id === portfolioId)
    Vue.set(portfolio, '__users_loading', true)
    // Vue.set(portfolio, '__users_loaded', false)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },
  // When users have been loaded
  usersLoaded (state, { portfolioId, users }) {
    let portfolio = state.portfolios.find(o => o.id === portfolioId)
    Vue.set(portfolio, '__users_loading', false)
    Vue.set(portfolio, '__users_loaded', true)
    Vue.set(portfolio, 'users', users)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },
  // When details are being loaded
  detailsLoading (state, { portfolioId }) {
    let portfolio = state.portfolios.find(o => o.id === portfolioId)
    Vue.set(portfolio, '__details_loading', true)
    // Vue.set(portfolio, '__details_loaded', false)
    Vue.set(state, 'portfolios', [...state.portfolios])
  },
  // When details have been loaded
  detailsLoaded (state, { portfolioId, details }) {
    const sortedScenarios = [...details.scenarios].sort((a, b) => a.description > b.description ? 1 : -1)
    details.scenarios = sortedScenarios.map((s, i) => {
      s.color = colorPalettes.scenarioColors[i % colorPalettes.scenarioColors.length]
      return s
    })
    let portfolios = state.portfolios.map((portfolio) => {
      if (portfolio.id === portfolioId) {
        return {
          ...portfolio,
          ...details,
          '__details_loading': false,
          '__details_loaded': true
        }
      } else {
        return portfolio
      }
    })
    Vue.set(state, 'portfolios', [...portfolios])
  },
  portfoliosLoading (state) {
    Vue.set(state, 'portfolios_loading', true)
    Vue.set(state, 'portfolios_loaded', false)
  },
  // After portfolios have been loaded
  portfoliosLoaded (state, { portfolios }) {
    portfolios.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
    portfolios.forEach(p => {
      p.__buildings_loading = false
      p.__buildings_loaded = false
      p.__details_loading = false
      p.__details_loaded = false
      p.__users_loading = false
      p.__users_loaded = false
    })
    state.portfolios = portfolios
    Vue.set(state, 'portfolios_loading', false)
    Vue.set(state, 'portfolios_loaded', true)
  },
  /*
   * Modify search query
   */
  setQuery (state, newQuery) {
    Vue.set(state, 'query', newQuery)
  },
  setTagFilters (state, { tagGroupId, tagIdFilters }) {
    if (tagIdFilters.length > 0) {
      state.query.filters[tagGroupId] = [...tagIdFilters]
    } else {
      delete state.query.filters[tagGroupId]
    }
    Vue.set(state, 'query', { ...state.query })
  },
  clearTagFilters (state) {
    state.query.filters = {}
    Vue.set(state, 'query', { ...state.query })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
