import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import building from './modules/building'
import locale from './modules/locale'
import portfolio from './modules/portfolio'
import users from './modules/users'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    account,
    building,
    locale,
    portfolio,
    users
  },
  strict: debug
})
