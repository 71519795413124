import compassApi from '@/services/compass-api'
import Vue from 'vue'

// initial state
const state = {
  buildings: []
}

// getters
const getters = {
  getById (state) {
    return (buildingId) => {
      return state.buildings.find(b => b.id === buildingId)
    }
  }
}

// actions
const actions = {
  /*
   * Loaders
   */
  async loadDetailsById ({ state, commit }, buildingId) {
    const building = state.buildings.find(b => b.id === buildingId)
    if (!building || (!building.__loading && !building.__loaded)) {
      // Only load if not already loaded or currently loading
      commit('detailsLoading', buildingId)
      const details = await compassApi.call(`/buildings/${buildingId}`)
      commit('detailsLoaded', { buildingId, details })
    }
  },
  async refreshDetailsById ({ commit }, buildingId) {
    commit('detailsLoading', buildingId)
    const details = await compassApi.call(`/buildings/${buildingId}`)
    commit('detailsLoaded', { buildingId, details })
  },
  /*
   * Create, modify and delete buildings
   */
  async deleteBuilding ({ dispatch }, { buildingId, portfolioId }) {
    await compassApi.call(`/buildings/${buildingId}`, 'DELETE')
    dispatch('portfolio/loadBuildingsById', portfolioId, { root: true })
  },
  async updateBuilding ({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  /*
   * Edit tags
   */
  async setTag ({ dispatch }, { buildingId, groupId, tagId }) {
    await compassApi.call(`/buildings/${buildingId}/tags/${groupId}`, 'PUT', {
      'tag_id': tagId
    })
    dispatch('refreshDetailsById', buildingId)
  },
  /*
   * Trigger GIS data refresh
   */
  async queryRoofTiles ({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/roof`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  async queryGwr ({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/gwr`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  async queryCadastre ({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/cadastre`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  async queryGeothermal ({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/geothermal`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  async queryEnergyGrids ({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/energy_grids`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  /*
   * Create, modify and delete Measures
   */
  async addMeasure ({ dispatch }, measure) {
    await compassApi.call(`/measures`, 'POST', measure)
    dispatch('refreshDetailsById', measure.building_id)
  },
  async updateMeasure ({ dispatch }, measure) {
    await compassApi.call(`/measures/${measure.id}`, 'PUT', measure)
    dispatch('refreshDetailsById', measure.building_id)
  },
  async deleteMeasure ({ dispatch }, measure) {
    await compassApi.call(`/measures/${measure.id}`, 'DELETE')
    dispatch('refreshDetailsById', measure.building_id)
  },
  /*
   * Create, modify and delete Meters and MeterValues
   */
  async addMeter ({ dispatch }, meter) {
    await compassApi.call(`/meters`, 'POST', meter)
    dispatch('refreshDetailsById', meter.building_id)
  },
  async updateMeter ({ dispatch }, meter) {
    await compassApi.call(`/meters/${meter.id}`, 'PUT', meter)
    dispatch('refreshDetailsById', meter.building_id)
  },
  async deleteMeter ({ dispatch }, meter) {
    await compassApi.call(`/meters/${meter.id}`, 'DELETE')
    dispatch('refreshDetailsById', meter.building_id)
  },
  async addMeterValue ({ dispatch }, { buildingId, value }) {
    await compassApi.call(`/meters/${value.meter_id}/values`, 'POST', value)
    dispatch('refreshDetailsById', buildingId)
  },
  async updateMeterValue ({ dispatch }, { buildingId, value }) {
    await compassApi.call(`/meters/${value.meter_id}/values/${value.id}`, 'PUT', value)
    dispatch('refreshDetailsById', buildingId)
  },
  async deleteMeterValue ({ dispatch }, { buildingId, value }) {
    await compassApi.call(`/meters/${value.meter_id}/values/${value.id}`, 'DELETE')
    dispatch('refreshDetailsById', buildingId)
  }
}

// mutations
const mutations = {
  detailsLoading (state, { buildingId }) {
    let buildings = state.buildings.filter(b => b.id !== buildingId)
    Vue.set(state, 'buildings', [...buildings, {
      id: buildingId,
      __loading: true,
      __loaded: false
    }])
  },
  detailsLoaded (state, { buildingId, details }) {
    let buildings = state.buildings.filter(b => b.id !== buildingId)
    Vue.set(state, 'buildings', [...buildings, {
      ...details,
      __loading: false,
      __loaded: true
    }])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
