import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const dateTimeFormats = {
  de: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
    }
  // },
  // en: {
  //   short: {
  //     year: 'numeric', month: 'short', day: 'numeric'
  //   },
  //   long: {
  //     year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
  //   }
  // },
  // fr: {
  //   short: {
  //     year: 'numeric', month: 'short', day: 'numeric'
  //   },
  //   long: {
  //     year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
  //   }
  }
}

function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  fallbackLocale: 'de',
  silentFallbackWarn: true,
  dateTimeFormats: dateTimeFormats,
  messages: loadLocaleMessages()
})

export default i18n
