<template>
  <div id="app" :class="appClasses">
    <keep-alive include="portfolio-page">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import Chart from 'chart.js'

Chart.defaults.global.defaultFontFamily = 'Source Sans Pro'
Chart.defaults.global.defaultFontSize = 14

export default {
  computed: {
    appClasses () {
      return {
        'sticky-header': this.scrollY > 70
      }
    }
  },
  data: function () {
    return {
      scrollY: null
    }
  },
  mounted () {
    window.addEventListener('scroll', (event) => {
      this.scrollY = Math.round(window.scrollY)
    })
  }
}
</script>

<style>
/*
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, main,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
sub, sup {
    font-size: .5em;
}
sub {
    vertical-align: sub;
}
sup {
    vertical-align: super;
}
strong {
  font-weight: 700;
}
/* Sanitise box-sizing */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* Compass styles */

/* Scale: http://www.modularscale.com/?1.15&em&1.667 */

:root {
  --font-xl: 3.375em;
  --font-l: 1.85em;
  --font-m: 1.3em;
  --font-s: 1em;
  --font-xs: 0.8em;

  --font-family: "Source Sans Pro", "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode", "Open Sans", "Droid Sans", sans-serif;

  --header-height: 125px;
  --sticky-header-height: 50px;

  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0;
  font-feature-settings: "kern", "liga", "clig", "calt", "onum", "pnum";

  --link-color: rgba(0,0,0,0.7);
  --link-color-hover: rgba(0,0,0,1.0);

  --text-color: hsl(0, 0%, 13%);
  --secondary-text-color: hsla(0, 0%, 13%, 0.6);
  --inverted-text-color: #ffffff;
  --highlight-color: hsl(202, 92%, 14%);
  --secondary-highlight-color: hsla(202, 92%, 14%, 0.82);
  --background-color: #ffffff;
  --secondary-background-color: hsla(202, 80%, 90%, 1.0);
  --translucent-background-color: hsla(0, 0%, 0%, 0.6);
  --shadow-color: hsla(202, 92%, 14%, 0.3);

  --success-color: #76cd99;
  --error-color: #e7595a;
  --warning-color: #fb805d;

  --ranking-1-color: #c65051;
  --ranking-2-color: #fb805d;
  --ranking-3-color: #ef9852;
  --ranking-4-color: #f1c757;
  --ranking-5-color: #5fccf0;

  --track-color: #e0e0e0;

  --popover-background-color: #333333;
  --popover-text-color: #ffffff;
  --popover-hairline-color: #444444;

  --spacing-xxl: 4.632rem;
  --spacing-xl: 2.779rem;
  --spacing-l: 1.667rem;
  --spacing-m: 1rem;
  --spacing-s: .6rem;
  --spacing-xs: .36rem;
  --spacing-xxs: .216rem;

  --box-padding-h: var(--spacing-s);
  --box-padding-v: var(--spacing-xs);
  --box-padding: var(--box-padding-v) var(--box-padding-h);
  --box-border-width: 1px;
  --box-border: var(--box-border-width) solid var(--hairline-color);
  --box-radius: var(--spacing-xs);
  --box-two-column-width: (100% - var(--box-padding-h)) / 2;
  --box-error-border-width: 2px;
  --box-error-border: var(--box-error-border-width) solid var(--warning-color);

  --hairline-width: 1px;
  --hairline-color: hsla(202, 92%, 14%, 0.12);
  --default-column-width: 14rem;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
  :root {
    --hairline-width: 0.5px;
  }
}

@media
only screen and (-webkit-min-device-pixel-ratio: 3),
only screen and (   min--moz-device-pixel-ratio: 3),
only screen and (     -o-min-device-pixel-ratio: 3/1),
only screen and (        min-device-pixel-ratio: 3),
only screen and (                min-resolution: 288dpi),
only screen and (                min-resolution: 3dppx) {
  :root {
    --hairline-width: calc(1px/3);
  }
}

@supports (font-kerning: normal) and (font-variant ligatures: common-ligatures contextual) and (font-variant-numeric: oldstyle-nums proportional-nums) {
  :root {
    font-kerning: normal;
    font-variant-ligatures: common-ligatures contextual;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-feature-settings: normal;
  }
}

html {
  box-sizing: border-box;
  position: relative;
  min-height: 100%;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  line-height: 1.4;
  background-color: var(--background-color);
  color: var(--text-color);
  margin-bottom: 80px; /* =footer height */
}

p ~ p {
  margin: .75em 0 0;
}

a,
a:visited {
  color: var(--link-color);
  text-decoration: none;
  /* text-decoration: underline;
  text-decoration-skip: ink;
  text-decoration-color: #D7999D; */
  -webkit-tap-highlight-color: transparent;
}

a:hover {
  color: var(--link-color-hover);
}

abbr {
  letter-spacing: .01em;
  font-feature-settings: "kern", "liga", "clig", "calt", "c2sc", "smcp";
}
@supports (font-variant-caps: all-small-caps) {
  abbr {
    font-variant-caps: all-small-caps;
    font-feature-settings: normal;
  }
}
.numbers {
  letter-spacing: .01em;
}
img {
  max-width: 100%;
}

.match {
  display: inline-block;
  background-color: hsl(60, 80%, 90%);
  border-radius: .3em;
  padding: 0 .3em;
  margin: 0 -.3em;
  /* transform: translate(-.3em); */
}

label {
  -webkit-tap-highlight-color: transparent;
}

input[type=text],
input[type=password],
textarea,
.button {
  font-weight: 300;
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-s);
  line-height: 1.4;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  transition: background-color .2s, border-color .2s;
}

input[type=text],
input[type=password],
textarea {
  background-color: var(--background-color);
  border: var(--box-border);
  border-radius: var(--box-radius);
  color: var(--highlight-color);
  font-weight: 300;
}

input[type=text],
input[type=password] {
  padding: var(--box-padding);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input[type=text]::placeholder,
input[type=password]::placeholder {
  color: var(--secondary-highlight-color);
}

/*
input[type=text],
input[type=password],
textarea {
  min-width: var(--default-column-width);
}
*/

.button {
  background-color: #f8f8f8;
  padding: var(--box-padding);
  border: var(--box-border);
  border-radius: var(--box-radius);
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  -webkit-tap-highlight-color: transparent;
}

.button + .button {
  margin-left: var(--spacing-xs);
}

.button .icon {
  opacity: 0.7;
  vertical-align: text-top;
}

.button:hover {
  border-color: #aaa;
}

.button:hover .icon {
  opacity: 0.9;
}

.button.active {
  border-color: var(--secondary-background-color);
  background-color: var(--secondary-background-color);
}

.button.active .icon {
  opacity: 0.6;
}

/*
.button:active {
  color: var(--secondary-highlight-color);
  border-color: var(--highlight-color);
}
*/

.button:disabled {
  color: #aaaaaa;
  /* border: var(--box-error-border); */
}

.input_false {
  background-image: none !important;
  background-color: #f2dede !important;
  box-shadow: 0 0 0px 1000px #f2dede inset !important;
}

table {
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid var(--text-color);
}

input[type=checkbox], input[type=radio] {
  /* Larger checkboxes */
  -ms-transform: scale(1.33); /* IE */
  -moz-transform: scale(1.33); /* FF */
  -webkit-transform: scale(1.33); /* Safari and Chrome */
  -o-transform: scale(1.33); /* Opera */
  transform: scale(1.33);
  margin: var(--spacing-s);
}

@media screen and (prefers-reduced-motion: reduce) {
  * {
      animation-duration: 0 !important;
      transition-duration: 0 !important;
  }
}

.sticky-header .compass-header .secondary-nav {
  position: fixed;
  top: 0;
  width:100%;
  z-index: 3;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 1px var(--shadow-color);
}

.sticky-header .compass-header .primary-nav {
  margin-bottom: var(--sticky-header-height);
}

@import url("https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css");
/* @import url("https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css"); */

h1 {
  font-size: var(--font-l);
  font-weight: 900;
  color: var(--secondary-highlight-color);
  margin-bottom: var(--spacing-m)
}

h2 {
  font-size: var(--font-m);
  font-weight: 700;
  color: var(--secondary-highlight-color);
  margin-bottom: var(--spacing-s)
}

h3 {
  font-size: var(--font-s);
  font-weight: 700;
  color: var(--secondary-highlight-color);
  margin-bottom: var(--spacing-s);
  margin-top: var(--spacing-l);
}

h4 {
  font-weight: 400;
  color: var(--secondary-highlight-color);
  margin-bottom: var(--spacing-s);
  margin-top: var(--spacing-s);
}

h5 {
  font-weight: 400;
  color: var(--secondary-highlight-color);
}

/*
@media screen and (min-width: 35.65rem) {
  body {
    background-color: lightcyan;
  }
}

@media screen and (min-width: 52.9rem) {
  body {
    background-color: lightcoral;
  }
}

@media screen and (min-width: 87.4rem) {
  body {
    background-color: palegoldenrod;
  }
}

@media screen and (min-width: 104.65rem) {
  body {
    background-color: plum;
  }
}

@media screen and (min-width: 139.15rem) {
  body {
    background-color: lightskyblue;
  }
}
*/
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: var(--box-radius);
  padding: var(--spacing-s) 0;
  /* padding: 5px 10px 4px; */
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: var(--popover-background-color);
  color: var(--popover-text-color);
  border-radius: var(--box-radius);
  box-shadow: 0 5px 30px rgba(black, .1);
  max-width: 500px;
}

.tooltip.popover .popover-inner header {
  font-size: var(--font-s);
  font-weight: 500;
  padding: var(--spacing-xs) var(--spacing-m);
}

.tooltip.popover .popover-inner main {
  font-size: var(--font-s);
  padding: var(--spacing-xs) var(--spacing-m);
}

.tooltip.popover .popover-inner main li {
  padding: var(--spacing-s);
  margin-left: var(--spacing-m);
}

.tooltip.popover .popover-inner main ~ main {
  border-top:1px solid var(--popover-hairline-color);
}

.tooltip.popover .popover-arrow {
  border-color: var(--popover-background-color);
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
/*
.vs__selected-options {
  min-width: var(--default-column-width);
}
*/
</style>
